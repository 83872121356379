<template>
  <div class='flex flex-col lg:flex-row justify-start lg:justify-between items-stretch gap-y-4 lg:gap-y-0 lg:gap-x-20 px-4 lg:px-0'>
    <hotel-and-tour-navigation class='flex-shrink-0'>
      <ul class='mt-2 mb-12 pl-2  whitespace-pre-line text-gray-100 text-sm'>
        <li>
          <a class='hover:underline cursor-pointer'
            v-scroll-to="{ 
              el: '#hotel-inter-continental-seoul-coex',
              offset: -100
            }">
            InterContinental Seoul Coex
          </a>
        </li>
        <li class='mt-1'>
          <a class='hover:underline cursor-pointer'
            v-scroll-to="{ 
              el: '#hotel-grand-inter-continental-seoul-parnas',
              offset: -100
            }">
            Grand InterContinental Seoul Parnas
          </a>
        </li>
        <li class='mt-1'>
          <a class='hover:underline cursor-pointer'
            v-scroll-to="{ 
              el: '#hotel-oakwood-premier-coex-center',
              offset: -100
            }">
            Oakwood Premier Coex Center
          </a>
        </li>
        <li class='mt-1'>
          <a class='hover:underline cursor-pointer'
            v-scroll-to="{ 
              el: '#hotel-hotel-riviera',
              offset: -100
            }">
            Hotel Riviera
          </a>
        </li>
        <li class='mt-1'>
          <a class='hover:underline cursor-pointer'
            v-scroll-to="{ 
              el: '#hotel-l7-gangnam',
              offset: -100
            }">
            L7 Gangnam
          </a>
        </li>
        <li class='mt-1'>
          <a class='hover:underline cursor-pointer'
            v-scroll-to="{ 
              el: '#hotel-shilla-stay-samsung',
              offset: -100
            }">
            Shilla Stay Samsung
          </a>
        </li>
        <li class='mt-1'>
          <a class='hover:underline cursor-pointer'
            v-scroll-to="{ 
              el: '#hotel-hotel-peyto-samseong',
              offset: -100
            }">
            Hotel Peyto Samseong
          </a>
        </li>
        <li class='mt-1'>
          <a class='hover:underline cursor-pointer'
            v-scroll-to="{ 
              el: '#hotel-ibis-styles-ambassador-seoul-gangnam',
              offset: -100
            }">
            Ibis Styles Ambassador Seoul Gangnam
          </a>
        </li>
        <li class='mt-1'>
          <a class='hover:underline cursor-pointer'
            v-scroll-to="{ 
              el: '#hotel-aloft-seoul-gangnam',
              offset: -100
            }">
            Aloft Seoul Gangnam
          </a>
        </li>
        <li class='mt-1'>
          <a class='hover:underline cursor-pointer'
            v-scroll-to="{ 
              el: '#hotel-hotel-uri',
              offset: -100
            }">
            Hotel URI&
          </a>
        </li>
      </ul>
    </hotel-and-tour-navigation>
    <div class='flex-grow py-16'>
      <welcome-title title='Hotel Information' />
      <h1 class='text-2xl font-semibold mb-4'>List of 2022 APSR Hotels</h1>
      <div id='hotel-inter-continental-seoul-coex' class='mb-16'>
        <h2 class='text-lg lg:text-xl font-semibold w-full lg:w-1/2'>1. InterContinental Seoul Coex <span class='text-red-500'>(★★★★★)</span></h2>
        <div class='mt-8 flex flex-row gap-x-4'>
          <img src='https://apsr2022.org/img/sub/h1-1.png' class='w-5/12'>
          <img src='https://apsr2022.org/img/sub/h1-2.png' class='w-5/12'>
        </div>
        <div class='mt-8'>
          <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Call
                </div>
                <div class='text-sm'>
                  +82-2-3452-2500
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Web-site
                </div>
                <div class='text-sm'>
                  <a href='https://seoul.intercontinental.com/iccoex/eng/' class='underline' target='_blank'>https://seoul.intercontinental.com/iccoex/eng/</a>
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Address
                </div>
                <div class='text-sm'>
                  524, Bongeunsa-ro, Gangnam-gu, Seoul, Korea
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Distance from venue(Coex)
                </div>
                <div class='text-sm'>
                  302m (5mins on foot)
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Check-In & Out
                </div>
                <div class='text-sm'>
                  15:00 / 11:00
                </div>
              </div>
            </li>
            <li class='list-outside'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Services and amenities
                </div>
                <div class='text-sm'>
                  High-speed wired and wireless internet connectivity<br>
                  42-inch LCD flat-panel television<br>
                  Hair dryer (4)In-room safe<br>
                  L'Occitane bath amenities<br>
                  Nespresso coffee machine and a selection of teas
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div id='hotel-grand-inter-continental-seoul-parnas' class='mb-16'>
        <h2 class='text-lg lg:text-xl font-semibold w-full lg:w-1/2'>2. Grand InterContinental Seoul Parnas <span class='text-red-500'>(★★★★★)</span></h2>
        <div class='mt-8 flex flex-row gap-x-4'>
          <img src='https://apsr2022.org/img/sub/h2-1.png' class='w-5/12'>
          <img src='https://apsr2022.org/img/sub/h2-2.png' class='w-5/12'>
        </div>
        <div class='mt-8'>
          <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Call
                </div>
                <div class='text-sm'>
                  +82-2-555-5656
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Web-site
                </div>
                <div class='text-sm'>
                  <a href='https://seoul.intercontinental.com/grandicparnas/eng' class='underline' target='_blank'>https://seoul.intercontinental.com/grandicparnas/eng</a>
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Address
                </div>
                <div class='text-sm'>
                  521, Teheran-ro, Gangnam-gu, Seoul, Korea
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Distance from venue(Coex)
                </div>
                <div class='text-sm'>
                  407m (6mins on foot)
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Check-In & Out
                </div>
                <div class='text-sm'>
                  15:00 / 11:00
                </div>
              </div>
            </li>
            <li class='list-outside'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Services and amenities
                </div>
                <div class='text-sm'>
                  High-speed wired and wireless internet access (Wi-Fi)<br>
                  UHD 55” TV<br>
                  OTT Service<br>
                  Hair Dryer<br>
                  In-room safe<br>
                  Weight scale, shaving mirror<br>
                  Bluetooth Speaker<br>
                  Mobile phone Charger<br>
                  Simmons Beautyrest mattress<br>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div id='hotel-oakwood-premier-coex-center' class='mb-16'>
        <h2 class='text-lg lg:text-xl font-semibold w-full lg:w-1/2'>3. Oakwood Premier Coex Center <span class='text-red-500'>(★★★★★)</span></h2>
        <div class='mt-8 flex flex-row gap-x-4'>
          <img src='https://apsr2022.org/img/sub/h3-1.png' class='w-5/12'>
          <img src='https://apsr2022.org/img/sub/h3-2.png' class='w-5/12'>
        </div>
        <div class='mt-8'>
          <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Call
                </div>
                <div class='text-sm'>
                  +82-2-3466-7000
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Web-site
                </div>
                <div class='text-sm'>
                  <a href='https://www.discoverasr.com/en/oakwood/korea-south/oakwood-premier-coex-center-seoul' class='underline' target='_blank'>https://www.discoverasr.com/en/oakwood/korea-south/oakwood-premier-coex-center-seoul</a>
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Address
                </div>
                <div class='text-sm'>
                  46 Teheran-ro 87-gil Gangnam-gu, 135-525, Seoul, Korea
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Distance from venue(Coex)
                </div>
                <div class='text-sm'>
                  57m (1min on foot)
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Check-In & Out
                </div>
                <div class='text-sm'>
                  15:00 / 12:00
                </div>
              </div>
            </li>
            <li class='list-outside'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Services and amenities
                </div>
                <div class='text-sm'>
                  Full-size TV, DVD<br>
                  Washing machine<br>
                  Iron, ironing board<br>
                  Bidet<br>
                  International direct call, voicemail box<br>
                  Private safe<br>
                  Hair dryer<br>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div id='hotel-hotel-riviera' class='mb-16'>
        <h2 class='text-lg lg:text-xl font-semibold w-full lg:w-1/2'>4. Hotel Riviera  <span class='text-red-500'>(★★★★)</span></h2>
        <div class='mt-8 flex flex-row gap-x-4'>
          <img src='https://apsr2022.org/img/sub/h5-1.png' class='w-5/12'>
          <img src='https://apsr2022.org/img/sub/h5-2.png' class='w-5/12'>
        </div>
        <div class='mt-8'>
          <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Call
                </div>
                <div class='text-sm'>
                  +82-2-541-3111
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Web-site
                </div>
                <div class='text-sm'>
                  <a href='http://www.hotelriviera.co.kr/anobli/html/main.asp' class='underline' target='_blank'>http://www.hotelriviera.co.kr/anobli/html/main.asp</a>
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Address
                </div>
                <div class='text-sm'>
                  737, Yeongdong-daero, Gangnam-gu, Seoul, Korea
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Distance from venue(Coex)
                </div>
                <div class='text-sm'>
                  1.8km (6mins by car)
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Check-In & Out
                </div>
                <div class='text-sm'>
                  14:00 / 12:00
                </div>
              </div>
            </li>
            <li class='list-outside'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Services and amenities
                </div>
                <div class='text-sm'>
                  Safe box<br>
                  Mineral water<br>
                  Air conditioning<br>
                  Slippers<br>
                  Refrigerator<br>
                  Bidet<br>
                  WIFI<br>
                  42-inch TV<br>
                  Bath/shower stall<br>
                  24-hours Message<br>
                  Toiletries<br>
                  Teapot/ Tea bag<br>
                  Hair Dryer<br>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div id='hotel-l7-gangnam' class='mb-16'>
        <h2 class='text-lg lg:text-xl font-semibold w-full lg:w-1/2'>5. L7 Gangnam <span class='text-red-500'>(★★★★)</span></h2>
        <div class='mt-8 flex flex-row gap-x-4'>
          <img src='https://apsr2022.org/img/sub/h4-1.png' class='w-5/12'>
          <img src='https://apsr2022.org/img/sub/h4-2.png' class='w-5/12'>
        </div>
        <div class='mt-8'>
          <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Call
                </div>
                <div class='text-sm'>
                  +82-2-2011-1000
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Web-site
                </div>
                <div class='text-sm'>
                  <a href='https://www.lottehotel.com/gangnam-l7/en.html' class='underline' target='_blank'>https://www.lottehotel.com/gangnam-l7/en.html</a>
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Address
                </div>
                <div class='text-sm'>
                  415, Teheran-ro, Gangnam-gu, Seoul, Korea
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Distance from venue(Coex)
                </div>
                <div class='text-sm'>
                  1.1km (8mins by car)
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Check-In & Out
                </div>
                <div class='text-sm'>
                  15:00 / 12:00
                </div>
              </div>
            </li>
            <li class='list-outside'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Services and amenities
                </div>
                <div class='text-sm'>
                  43” UHD TV Flashlight Slippers Alarm clock Phone Teapot Safe Office desk<br>
                  Bathroom amenities (MALIN+GOETZ) Disposable toothbrushes and toothpaste Bathrobe Shower cap Bidet Hair dryer Cotton swabs<br>
                  Two bottles of complimentary mineral water daily Free drip coffee
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div id='hotel-shilla-stay-samsung' class='mb-16'>
        <h2 class='text-lg lg:text-xl font-semibold w-full lg:w-1/2'>6. Shilla Stay Samsung <span class='text-red-500'>(★★★★)</span></h2>
        <div class='mt-8 flex flex-row gap-x-4'>
          <img src='https://apsr2022.org/img/sub/h6-1.png' class='w-5/12'>
          <img src='https://apsr2022.org/img/sub/h6-2.png' class='w-5/12'>
        </div>
        <div class='mt-8'>
          <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Call
                </div>
                <div class='text-sm'>
                  +82-2-2175-9000
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Web-site
                </div>
                <div class='text-sm'>
                  <a href='https://www.shillastay.com/samsung/index.do' class='underline' target='_blank'>https://www.shillastay.com/samsung/index.do</a>
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Address
                </div>
                <div class='text-sm'>
                  506, Yeongdong-daero, Gangnam-gu, Seoul, Korea
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Distance from venue(Coex)
                </div>
                <div class='text-sm'>
                  286m (6mins on foot)
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Check-In & Out
                </div>
                <div class='text-sm'>
                  15:00 / 12:00
                </div>
              </div>
            </li>
            <li class='list-outside'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Services and amenities
                </div>
                <div class='text-sm'>
                  40” LED TV (35 channels)<br>
                  Desk<br>
                  Refrigerator<br>
                  Complimentary coffee and tea bags<br>
                  Complimentary 2bottles of mineral water (per night)<br>
                  Free wired/wireless internet access<br>
                  Universal adapter (220V Only)<br>
                  Safety deposit box<br>
                  Shampoo, hair conditioner, shower gel, body lotion, soap<br>
                  Two bathrobes<br>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div id='hotel-hotel-peyto-samseong' class='mb-16'>
        <h2 class='text-lg lg:text-xl font-semibold w-full lg:w-1/2'>7. Hotel Peyto Samseong <span class='text-red-500'>(★★★)</span></h2>
        <div class='mt-8 flex flex-row gap-x-4'>
          <img src='https://apsr2022.org/img/sub/h8-1.png' class='w-5/12'>
        </div>
        <div class='mt-8'>
          <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Call
                </div>
                <div class='text-sm'>
                  +82-2-6936-8100
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Web-site
                </div>
                <div class='text-sm'>
                  <a href='https://www.peytohotel.com/Samseong/view/index.do' class='underline' target='_blank'>https://www.peytohotel.com/Samseong/view/index.do</a>
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Address
                </div>
                <div class='text-sm'>
                  9, Teheran-ro 87gil, Gangnam-gu, Seoul, Korea
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Distance from venue(Coex)
                </div>
                <div class='text-sm'>
                  247m (4mins on foot)
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Check-In & Out
                </div>
                <div class='text-sm'>
                  15:00 / 12:00
                </div>
              </div>
            </li>
            <li class='list-outside'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Services and amenities
                </div>
                <div class='text-sm'>
                  40-inch LCD TV<br>
                  Down Feather Duvet<br>
                  ORGA HOUSE Amenities<br>
                  toothbrushes and toothpaste, Shower cap, Cotton swabs<br>
                  Bathrobe Hairdryer, Slippers, Teapot<br>
                  Complimentary Mineral Water<br>
                  Refrigerator<br>
                  In-Room Safe<br>
                  Work Desk with Adjustable Lamp<br>
                  Free Wired and Wireless High Speed Internet Access
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div id='hotel-ibis-styles-ambassador-seoul-gangnam' class='mb-16'>
        <h2 class='text-lg lg:text-xl font-semibold w-full lg:w-1/2'>8. Ibis Styles Ambassador Seoul Gangnam <span class='text-red-500'>(★★★)</span></h2>
        <div class='mt-8 flex flex-row gap-x-4'>
          <img src='https://apsr2022.org/img/sub/h10-1.png' class='w-5/12'>
          <img src='https://apsr2022.org/img/sub/h10-2.png' class='w-5/12'>
        </div>
        <div class='mt-8'>
          <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Call
                </div>
                <div class='text-sm'>
                  +82-2-3454-1101
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Web-site
                </div>
                <div class='text-sm'>
                  <a href='https://www.ambatel.com/ibisstyles/gangnam/en/main.do' class='underline' target='_blank'>https://www.ambatel.com/ibisstyles/gangnam/en/main.do</a>
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Address
                </div>
                <div class='text-sm'>
                  431 Samseong-ro, Gangnam-gu, Seoul, Korea
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Distance from venue(Coex)
                </div>
                <div class='text-sm'>
                  969m (5min by car)
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Check-In & Out
                </div>
                <div class='text-sm'>
                  14:00 / 12:00
                </div>
              </div>
            </li>
            <li class='list-outside'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Services and amenities
                </div>
                <div class='text-sm'>
                  Mini Bar, Coffee/tea making facilities, Free in Room Mineral Water, Mini-refrigerator, Fridge, Bottled water, Kettle<br>
                  Bathroom Facilities<br>
                  Bidet, Hair dryer in bathroom<br>
                  Phone<br>
                  Internet Facilities<br>
                  Wireless internet in your room<br>
                  Phone Facilities<br>
                  Voice mail
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div id='hotel-aloft-seoul-gangnam' class='mb-16'>
        <h2 class='text-lg lg:text-xl font-semibold w-full lg:w-1/2'>9. Aloft Seoul Gangnam <span class='text-red-500'>(★★★)</span></h2>
        <div class='mt-8 flex flex-row gap-x-4'>
          <img src='https://apsr2022.org/img/sub/h11-1.png' class='w-5/12'>
          <img src='https://apsr2022.org/img/sub/h11-2.png' class='w-5/12'>
        </div>
        <div class='mt-8'>
          <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Call
                </div>
                <div class='text-sm'>
                  +82-2-510-9700
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Web-site
                </div>
                <div class='text-sm'>
                  <a href='https://www.marriott.com/en-us/hotels/selal-aloft-seoul-gangnam/overview' class='underline' target='_blank'>https://www.marriott.com/en-us/hotels/selal-aloft-seoul-gangnam/overview</a>
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Address
                </div>
                <div class='text-sm'>
                  736 Yeongdong-daero, Gangnam-gu, Seoul, Korea
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Distance from venue(Coex)
                </div>
                <div class='text-sm'>
                  2.1km (8mins by car)
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Check-In & Out
                </div>
                <div class='text-sm'>
                  15:00 / 12:00
                </div>
              </div>
            </li>
            <li class='list-outside'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Services and amenities
                </div>
                <div class='text-sm'>
                  Shower<br>
                  Hair dryer<br>
                  Robe<br>
                  Slippers<br>
                  Alarm Clock<br>
                  Safe, in room<br>
                  Outlet with dual voltage adaptors<br>
                  Desk, writing / work, electrical outlet<br>
                  Bottled water, complimentary<br>
                  Coffee maker / tea service<br>
                  Mini-refrigerator
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div id='hotel-hotel-uri' class='mb-16'>
        <h2 class='text-lg lg:text-xl font-semibold w-full lg:w-1/2'>10. Hotel URI&( <span class='text-red-500'>(★★★)</span></h2>
        <div class='mt-8 flex flex-row gap-x-4'>
          <img src='https://apsr2022.org/img/sub/h9-1.png' class='w-5/12'>
          <img src='https://apsr2022.org/img/sub/h9-2.png' class='w-5/12'>
        </div>
        <div class='mt-8'>
          <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Call
                </div>
                <div class='text-sm'>
                  +82-2-500-2300
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Web-site
                </div>
                <div class='text-sm'>
                  <a href='http://www.uri-nhotel.com/view/index.do' class='underline' target='_blank'>http://www.uri-nhotel.com/view/index.do</a>
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Address
                </div>
                <div class='text-sm'>
                  20, Samseong-ro 96 gil, Gangnam-gu, Seoul, Korea
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Distance from venue(Coex)
                </div>
                <div class='text-sm'>
                  247m (4mins on foot)
                </div>
              </div>
            </li>
            <li class='list-outside mb-4'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Check-In & Out
                </div>
                <div class='text-sm'>
                  14:00 / 12:00
                </div>
              </div>
            </li>
            <li class='list-outside'>
              <div class='flex flex-col lg:flex-row items-start lg:items-center gap-x-4'>
                <div class='font-semibold w-full lg:w-60 flex-shrink-0'>
                  Services and amenities
                </div>
                <div class='text-sm'>
                  43-inch LCD TV<br>
                  Down Feather Duvet<br>
                  Toothbrush Set<br>
                  European-style bidet<br>
                  Bath Amenities, comb<br>
                  Work Desk with Lamp<br>
                  Complimentary Mineral Water<br>
                  Coffee&Tea in the room<br>
                  Refrigerator<br>
                  Free Wired and Wireless High Speed, Internet Access<br>
                  Temperature control system (heating and air- conditioning)<br>
                  Hairdryer, Bathrobe ,Slippers, In-Room Safe<br>
                  choosable Bath or shower booth
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HotelAndTourNavigation from '@/components/apsr2022/HotelAndTourNavigation.vue'
import WelcomeTitle           from '@/components/apsr2022/WelcomeTitle.vue'

export default {
  name: 'Hotels',
  components: {
    HotelAndTourNavigation,
    WelcomeTitle,
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
  }
}
</script>

